<template>
  <div class="columns is-multiline">
    <modal-document
      v-if="modalDocumentActive"
      :close="activeModalDocument"
      :viewDocument="viewDocument"
    />
    <div class="column is-8">
      <div class="box">
        <div class="columns is-multiline">
          <div class="column is-6">
            <h2 class="subtitle">
              Resultados
            </h2>
          </div>
          <!-- Listado de Documentos -->
          <div class="column is-12">
            <div class="columns is-multiline is-mobile">
              <div class="column is-4-desktop is-6-tablet is-6-mobile" v-for="(item, index) in resultsDocument" :key="index">
                <div class="has_file">
                  <div class="image_content">
                    <template v-if="item.result_type === FILE_DOCUMENT">
                        <img
                          class="image_pdf"
                          :src="require('@/assets/icon/' + imgUrl(item.name))"
                        >
                        <p class="file_name" @click="activeModalDocument(item.name)">{{item.documentName}}</p>
                      </template>
                      <template v-else>
                        <img class="image_pdf" src="@/assets/icon/web-file.png">
                        <p class="file_name"><a :href="item.name" target="_blank">{{item.documentName}}</a></p>
                      </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'Results',
  components: {
    ModalDocument: () => import('@/components/services/ModalDocument.vue')
  },
  data () {
    return {
      serviceId: this.$route.params.serviceId,
      customerId: this.$route.params.customerId || null,
      loading: false,
      isEmpty: 0,
      resultsDocument: [],
      FILE_DOCUMENT: 1,
      URL_DOCUMENT: 2,
      modalDocumentActive: false,
      viewDocument: null
    }
  },
  methods: {
    ...mapActions(['getServiceDocumentsById']),
    activeModalDocument (document = null) {
      if (document) this.viewDocument = document
      this.modalDocumentActive = !this.modalDocumentActive
    },
    imgUrl (fileName) {
      const ext = fileName.substr(fileName.lastIndexOf('.') + 1)
      const excel = ['xlsx', 'xls', 'xlsm', 'csv']
      const image = ['jpeg', 'jpg', 'png']
      const doc = ['doc', 'docx']

      if (excel.includes(ext)) {
        return 'excel-file.png'
      } else if (image.includes(ext)) {
        return 'image-file.png'
      } else if (doc.includes(ext)) {
        return 'word-file.png'
      } else {
        return 'pdf-file.png'
      }
    }
  },
  beforeMount () {
    this.getServiceDocumentsById({ serviceId: this.serviceId, customerId: this.customerId })
      .then(response => {
        if (response.success) {
          this.resultsDocument = response.results
        }
      })
  }
}
</script>

<style lang="scss" scoped>
  .box {
    padding: 2.5rem;
  }
  .has_file {
    display: flex;
  }
  .image_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 3px 24px 0 rgba(0, 73, 136, 0.12);
    border: solid 1px #e1e5eb;
    background-color: #ffffff;
    max-width: 136.6px;
    min-width: 100%;
    margin: 0 1rem;
    min-height: 156px;
  }
  .image_pdf {
    max-width: 40px;
    padding: 2rem 0;
  }
  .file_name {
    border-top: solid 1px #ebeded;
    cursor: pointer;
    padding: 0.5rem;
    min-width: 90%;
    text-align: center;
  }
  .file_name a {
    color: #0A0A0A;
  }
  .input {
    font-size: 14px;
  }
  .input_ibs {
    margin: 2rem 0 3rem 0;
  }
  .is-medium {
    width: 196px;
  }
  .input-width {
    width: 300px;
  }

  .file-cta {
    background-color: white;
    color: #4a4a4a;
  }
  .file.is-boxed.has-name .file-cta {
    border-radius: 0px;
    min-width: 300px;
  }
  .file.is-boxed.has-name .file-name {
    border-radius: 0px;
  }
  .image_file_input {
    max-width: 60px;
  }
  .file-label {
    font-size: 16px;
    line-height: 1.54;
    text-align: center;
    color: #404254;
    padding-top: 1rem;
  }
  .is-danger-outlined .file-cta {
    border: 1px solid red;
  }
  .has_file_input {
    font-size: 60px;
    color: var(--primary-green);
  }
</style>
